import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

let stage = 'development'
let appType = 'client'

if (typeof window !== 'undefined') {
  if (window.location.href.includes('tickaroo')) {
    stage = 'staging'
  } else if (window.location.href.includes('handball.net')) {
    stage = 'production'
  }
} else if (typeof process !== 'undefined') {
  appType = 'web_server'
  stage = process.env.NEXT_PUBLIC_APP_ENV
    ? process.env.NEXT_PUBLIC_APP_ENV
    : process.env.APP_ENV
      ? process.env.APP_ENV
      : 'development'
}

Bugsnag.start({
  // FIXME : this should probably be in a onfig file
  apiKey: 'f70a6d7ee6c37616120730a50e1b9f37',
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: stage,
  appType,
  autoTrackSessions: false,
  collectUserIp: false,
  generateAnonymousId: false
})

export default Bugsnag
