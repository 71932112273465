import Link from 'components/router/Link'
import Image from 'components/ui/basic/Image'
import { ComponentType, ReactChild, ReactNode } from 'react'
import { ChevronRight } from 'react-feather'

export type ListItemProps = {
  title: string
  text?: string
  logo?: string
  className?: string
  buttons?: ReactNode[]
  url?: string
  onClick?: () => void
}

const ConditionalWrapper: ComponentType<{
  condition: boolean
  wrapper: any
  children?: ReactNode
}> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const ConditionalUrlWrapper: ComponentType<{
  condition: boolean
  className?: string
  wrapper: any
  children?: ReactNode
}> = ({ condition, wrapper, className, children }) =>
  condition ? (
    wrapper(children)
  ) : (
    <div className={`list-item ${className}`}>{children}</div>
  )

const ListItem: ComponentType<ListItemProps> = ({
  title,
  text,
  logo,
  className = '',
  buttons,
  url = null,
  onClick
}) => (
  <ConditionalWrapper
    condition={buttons != null && buttons.length > 0}
    wrapper={(children: ReactChild) => (
      <div className="list-item-with-button">{children}</div>
    )}
  >
    <ConditionalUrlWrapper
      condition={url !== null}
      className={className}
      wrapper={(children: ReactChild) =>
        url && (
          <Link
            href={url}
            onClick={onClick}
            className={`list-item ${className}`}
          >
            {children}
          </Link>
        )
      }
    >
      <div className="list-item-content">
        {logo && (
          <div className="list-item-logo">
            <Image
              className="h-full w-full mr-4"
              src={logo}
              alt={title}
              objectFit={'contain'}
            />
          </div>
        )}
        <div
          className={`list-item-text-wrapper ${
            buttons &&
            (buttons?.length > 2
              ? 'pr-24 mr-2'
              : buttons?.length > 1
              ? 'pr-20'
              : buttons?.length === 1
              ? 'pr-14'
              : 'pr-3')
          }`}
        >
          <div className="list-item-title">{!title.trim() ? '–' : title}</div>
          {text && <div className="list-item-text">{text}</div>}
        </div>
      </div>
      <div className="list-item-icon-wrapper">
        <ChevronRight size="1.3em" className="list-item-icon" />
      </div>
    </ConditionalUrlWrapper>

    {buttons && buttons?.length > 0 && (
      <div className="list-item-buttons">{buttons}</div>
    )}
  </ConditionalWrapper>
)

export default ListItem
