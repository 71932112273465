import { clientDELETE, clientGET, clientHEAD, clientPOST, clientPUT } from 'api/client'
import { Team, TeamSummary } from 'types'
import { useEffect, useState } from 'react'
import { Session, useSession } from 'lib/session-client'
import { systemStatus, unsubscribe } from 'lib/push'

const myTeamApiBase = '/myhandball/1/myteams'
const teamsApiBase = '/sportdata/1/teams'


// interface MyTeamManagementHook {
//   loading: boolean
//   session?: any
//   teamAdded: boolean
//   addTeam: () => void
//   removeTeam: () => void
// }



// export const useMyTeamManagement = (team: Team): MyTeamManagementHook => {
//   const [session, loading] = useSession()
//   const [secondLoading, setSecondLoading] = useState<boolean>(false)
//   const [teamAdded, setTeamAdded] = useState<boolean>(false)


//   useEffect(() => {
//     if (session?.accessToken) {
//       setSecondLoading(true)
//       clientHEAD({
//         url: myTeamApiBase + `/${team.id}`,
//         token: session.accessToken
//       }).then((status) => {
//         setTeamAdded(status === 200)
//         setSecondLoading(false)
//       })
//     }
//   }, [session])

//   const addTeam = () => {
//     if (session) {
//       setSecondLoading(true)

//       addMyTeam(team.id, session.accessToken).then((d) => {
//         setTeamAdded(true)
//         setSecondLoading(false)
//       })
//     }
//   }

//   const removeTeam = () => {
//     if (session) {
//       setSecondLoading(true)

//       // precautiously remove push subscription, if pushes are active on device
//       systemStatus().then((pushActive) => {
//         if (pushActive === 'granted') {
//           return unsubscribe(`teamgroup.${team.teamGroupId}`)
//             .then(() => removeMyTeam(team.id, session.accessToken))
//             .then(() => {
//               setTeamAdded(false)
//             }).finally(() => {
//               setSecondLoading(false)
//             })
//         } else {
//           removeMyTeam(team.id, session.accessToken).then(() => {
//             setTeamAdded(false)
//           }).finally(() => {
//             setSecondLoading(false)
//           })
//         }
//       })
//     }
//   }

//   return {
//     loading: loading && secondLoading,
//     session,
//     teamAdded,
//     addTeam,
//     removeTeam,
//   }
// }


export const canAddMyTeam = (
  teamId: string,
  token: string
): Promise<boolean> => {
  // Possible status Codes are: 404 for invalid team-id, 200 if team is own, 204 if team is not own
  return clientHEAD({
    url: myTeamApiBase + `/${teamId}`,
    token
  }).then((status) => status !== 200)
}


export const addMyTeam = (teamId: string, token: string): Promise<any> => {
  // Possible status Codes are: 404 for invalid team-id, 201 on success
  return clientPOST({
    url: myTeamApiBase + `/${teamId}`,
    data: { teamId },
    token
  })
}

const removeMyTeam = (teamId: string, token: string): Promise<any> => {
  // Possible status Codes are: 404 for invalid team-id, 204 on success
  return clientDELETE({
    url: myTeamApiBase + `/${teamId}`,
    token
  })
}

export const useMyTeams = (session: Session | undefined): Team[] | null => {
  const [teams, setTeams] = useState<Team[] | null>(null)
  useEffect(() => {
    if (session?.accessToken) {
      clientGET({
        url: myTeamApiBase,
        token: session?.accessToken
      }).then((data) => setTeams(data.data))
    }
  }, [session])
  return teams
}

export const useMutateMyTeam = (
  session: Session
): {
  submitting: boolean
  reorderMyTeams: (teamGroupIds: number[]) => Promise<unknown>
  removeMyTeam: (team: Team) => Promise<void>
} => {
  const [submitting, setSubmitting] = useState(false)
  const removeTeam = async (team: Team) => {
    setSubmitting(true)

    // precautiously remove push subscription, if pushes are active on device
    const pushActive = await systemStatus();

    if (pushActive === 'granted') {
      return unsubscribe(`teamgroup.${team.teamGroupId}`)
        .then(() => removeMyTeam(team.id, session.accessToken))
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      return removeMyTeam(team.id, session.accessToken).finally(() => {
        setSubmitting(false)
      })
    }
  }

  const reorderMyTeams = (teamGroupIds: number[]) => {
    setSubmitting(true)
    return clientPUT({
      url: myTeamApiBase,
      data: {
        ids: teamGroupIds
      },
      token: session.accessToken
    }).finally(() => {
      setSubmitting(false)
    })
  }

  return {
    submitting,
    reorderMyTeams,
    removeMyTeam: removeTeam
  }
}

export const useMyTeamListSummary = (): Team[] | null => {
  const [session] = useSession()
  const [teams, setTeams] = useState<Team[] | null>(null)
  useEffect(() => {
    if (session?.accessToken) {
      clientGET({
        url: myTeamApiBase + '/summary',
        token: session?.accessToken
      }).then((data) => setTeams(data.data))
    }
  }, [session])
  return teams
}

export const useMyTeamSummary = (team: Team | null): TeamSummary | null => {
  const [session] = useSession()
  const [summary, setSummary] = useState<TeamSummary | null>(null)
  useEffect(() => {
    if (session?.accessToken && team) {
      clientGET({
        url: teamsApiBase + `/${team.id}/summary`,
        token: session?.accessToken
      }).then((data) => setSummary(data.data))
    }
  }, [session, team])
  return summary
}
