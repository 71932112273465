import { NotifiableError } from '@bugsnag/js'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import bugsnag from './bugsnag'

const pushEnvs = {
  prod: {
    vapidKey:
      'BD-CIZFFCd1Yog9kzGC0ksNBAXtuVIJf3uRW3jH2nAF29gOx5qGQr1Od-LXPW_qnLEU5OfVyYiXktWUyC1jFwX8',
    firebaseConfig: {
      apiKey: 'AIzaSyDJUzyTbpxCrxisSBY1kUNsZ9BLV5ceE88',
      authDomain: 'handball---production.firebaseapp.com',
      projectId: 'handball---production',
      storageBucket: 'handball---production.appspot.com',
      messagingSenderId: '416259095427',
      appId: '1:416259095427:web:2d5c35e85c2b1814e094b6'
    },
    sw: '/sw-prod.js'
  },
  dev: {
    vapidKey:
      'BK_JT0ivJBWwgiwvXTg21DwgqkpbXh1S4Fd6UBen4cy5bhobWfl_-gSqlhEVzNbeKUR3d8w8J-bRBETCnXCHh28',
    firebaseConfig: {
      apiKey: 'AIzaSyDb4YTiG40VpOWXlPmKXN3uQfUgQ2kXIKU',
      authDomain: 'handball---development.firebaseapp.com',
      projectId: 'handball---development',
      storageBucket: 'handball---development.appspot.com',
      messagingSenderId: '82150743801',
      appId: '1:82150743801:web:fa6c0cb37c107b145b4c28'
    },
    sw: '/sw-dev.js'
  }
}

declare global {
  interface Window {
    nativePushStatus: 'unsupported' | 'granted' | 'denied' | 'default'
    nativePushTokenPromise: Promise<string>
    nativeCallbackSetPushToken: (token: string) => void
    nativeRequestPushToken?: () => void
  }
}

function pushesBlockedAlert() {
  window.alert('Push Benachrichtigungen wurden abgelehnt')
}

let firebaseApp: any
export async function firebasePushToken(): Promise<string> {
  const pushEnv = window.location.href.match(/handball\.net/)
    ? pushEnvs.prod
    : pushEnvs.dev

  if (!firebaseApp) {
    firebaseApp = initializeApp(pushEnv.firebaseConfig)
  }

  const messaging = getMessaging()

  try {
    const registrations = await navigator.serviceWorker.getRegistrations()
    let worker: any
    registrations.forEach((value) => {
      if (value.scope.endsWith('/push-sw-v1')) {
        worker = value
      } else {
        value.unregister()
      }
    })

    if (!worker) {
      worker = await navigator.serviceWorker.register(pushEnv.sw, {
        scope: '/push-sw-v1',
        updateViaCache: 'all'
      })
    }

    const token = await getToken(messaging, {
      vapidKey: pushEnv.vapidKey,
      serviceWorkerRegistration: worker
    })

    if (token) {
      return token
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('messaging/permission-blocked') // emulate browser error here
    }
  } catch (err: unknown) {
    if (
      err instanceof Error &&
      typeof err.message === 'string' &&
      err.message.includes('messaging/permission-blocked')
    ) {
      pushesBlockedAlert()
    } else {
      bugsnag.notify(err as NotifiableError)
    }
    throw err
  }
}

export async function pushToken(): Promise<string> {
  if (window.nativePushStatus || window.nativeRequestPushToken) {
    if (!window.nativePushTokenPromise) {
      window.nativePushTokenPromise = new Promise<string>(function (resolve) {
        window.nativeCallbackSetPushToken = resolve
        if (window.nativeRequestPushToken) {
          window.nativeRequestPushToken()
        }
      })
    }
    return window.nativePushTokenPromise
  } else {
    return await firebasePushToken()
  }
}

const isSupportedCache = isSupported()

export async function systemStatus(): Promise<
  'unsupported' | 'granted' | 'denied' | 'default'
> {
  if (window.nativePushStatus) {
    return window.nativePushStatus
  } else if (await isSupportedCache) {
    return window.Notification.permission
  } else {
    return 'unsupported'
  }
}

export async function status(
  channel: string
): Promise<'unsupported' | 'on' | 'off'> {
  const sStatus = await systemStatus()
  if (sStatus === 'granted') {
    const token = await pushToken()
    // eslint-disable-next-line no-undef
    const result = await fetch(
      `/a/myhandball/1/push/${token}/status/${channel}`
    )

    return result.status === 204 ? 'on' : 'off'
  } else if (sStatus === 'default' || sStatus === 'denied') {
    return 'off'
  } else {
    return sStatus
  }
}

export async function unsubscribe(channel: string): Promise<'on' | 'off'> {
  const token = await pushToken()
  // eslint-disable-next-line no-undef
  const result = await fetch(
    `/a/myhandball/1/push/${token}/unsubscribe/${channel}`,
    { method: 'POST' }
  )

  return result.status === 204 ? 'off' : 'on'
}

export async function subscribe(channel: string): Promise<'on' | 'off'> {
  const token = await pushToken()
  // eslint-disable-next-line no-undef
  const result = await fetch(
    `/a/myhandball/1/push/${token}/subscribe/${channel}`,
    { method: 'POST' }
  )

  return result.status === 204 ? 'on' : 'off'
}
