import { clientDELETE, clientGET, clientHEAD, clientPOST, clientPUT } from './client'
import { Tournament } from '../types'
import { Session } from '../lib/session-client'
import { useEffect, useState } from 'react'

const myTournamentApiBase = '/myhandball/1/mytournaments'
// const teamsApiBase = '/sportdata/1/teams'

export const useMyTournaments = (session: Session | undefined): Tournament[] | null => {
  const [tournaments, setTournaments] = useState<Tournament[] | null>(null)
  useEffect(() => {
    if (session?.accessToken) {
      clientGET({
        url: myTournamentApiBase,
        token: session?.accessToken
      }).then((data) => setTournaments(data.data))
    }
  }, [session])
  return tournaments
}

export const canAddMyTournament = (
  tournamentId: string,
  token?: string
): Promise<boolean> => {
  // Possible status Codes are: 404 for invalid team-id, 200 if team is own, 204 if team is not own
  return clientHEAD({
    url: myTournamentApiBase + `/${tournamentId}`,
    token
  }).then((status) => status !== 200)
}

export const addMyTournament = (teamId: string, token?: string): Promise<any> => {
  // Possible status Codes are: 404 for invalid team-id, 201 on success
  return clientPOST({
    url: myTournamentApiBase + `/${teamId}`,
    data: { teamId },
    token
  })
}

export const useMutateMyTournament = (
  session: Session
): {
  submitting: boolean
  reorderMyTournaments: (tournamentIds: string[]) => Promise<unknown>
  removeMyTournament: (tournamentId: string) => Promise<void>
} => {
  const [submitting, setSubmitting] = useState(false)
  const removeMyTournament = (teamId: string) => {
    setSubmitting(true)
    // Possible status Codes are: 404 for invalid team-id, 204 on success
    return clientDELETE({
      url: myTournamentApiBase + `/${teamId}`,
      token: session.accessToken
    }).finally(() => {
      setSubmitting(false)
    })
  }
  const reorderMyTournaments = (tournamentIds: string[]) => {
    setSubmitting(true)
    return clientPUT({
      url: myTournamentApiBase,
      data: {
        ids: tournamentIds
      },
      token: session.accessToken
    }).finally(() => {
      setSubmitting(false)
    })
  }
  return {
    submitting,
    reorderMyTournaments,
    removeMyTournament
  }
}
