'use client'
// import { SearchOverlay } from 'components/search/SearchOverlay'
import Image from 'components/ui/basic/Image'
import { Heading } from 'components/ui/text/Heading'
import React, { PropsWithChildren, ReactElement } from 'react'
// import { Search } from 'react-feather'
import { PartnerAutoheroMyHandballPath } from 'shared/urlHelpers'

interface Props {
  children: React.ReactNode
}

export const MyHandballBanner: React.FC<Props> = ({
  children
}): ReactElement => {
  return (
    <MyHandballImage>
      <MyHandballBannerContent />
      {children}
    </MyHandballImage>
  )
}

export const MyHandballImage: React.FC<PropsWithChildren> = ({
  children
}): ReactElement => {
  return (
    <section id="myHandball" className="container-myhandball  my-5 md:my-10">
      {children}
    </section>
  )
}

export const MyHandballBannerContent: React.FC = (): ReactElement => {
  return (
    <div className="flex flex-wrap justify-between mb-3 items-center">
      <div className="mr-4">
        <Heading level={2} className="text-white mb-0 whitespace-nowrap">
          Mein Handball
        </Heading>
        <div className="h-px bg-white w-full" />
      </div>
      <div className="hidden sm:block">
        {/* <SearchOverlay
          searchElement={(p) => (
            <button
              className="bg-white rounded-full flex items-center p-1 px-3 pr-4 text-xs bg-opacity-90 hover:bg-opacity-100"
              {...p}
            >
              <Search className="text-gray-900 w-4 h-4 mr-2" />
              <span className="text-gray-500 mt-px pt-px">
                Teams und Ligen suchen
              </span>
            </button>
          )}
          defaultActiveTab="clubs"
          showAddTeamButton
        /> */}
      </div>
      <a
        href={PartnerAutoheroMyHandballPath()}
        target="_blank"
        rel="noreferrer"
        className="inline-block h-8"
      >
        <Image
          src="/img/partners/autohero/Badge-Logo_Autohero_horizontal.webp"
          objectFit="contain"
          className="w-full h-full"
        />
      </a>
    </div>
  )
}
