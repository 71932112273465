'use client'

import React, { ComponentType } from 'react'
import Ad, { AdBaseProps, adFormats } from './Ad'

const formats = adFormats.leaderBoard

type Type = keyof typeof formats

type LeaderBoardAdProps = ComponentType<
  AdBaseProps & {
    type: Type
  }
>

export const LeaderBoardAd: LeaderBoardAdProps = ({ type, ...rest }) => {
  return <Ad formatId={formats[type]} {...rest} />
}
